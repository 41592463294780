/* eslint-disable @typescript-eslint/naming-convention */
import React, { ChangeEvent, useCallback, useState } from 'react'
import { Formatter } from '../../utilities/utils'
import { ICartComponent } from './interface'
import { userBusiness } from '../../Api/business.api'
import useSWR from 'swr'
import { useLocation } from 'react-router-dom'

const CartItem: React.FC<ICartComponent> = ({
  deleteCartItem,
  product,
  quantity,
  unit_price,
  id,
  updateCartItem,
  cartItems,
}) => {
  const [values, setValue] = useState<string>(
    quantity !== 0 ? quantity.toString() : '1'
  )
  const { pathname } = useLocation()
  const getAmount = useCallback(() => {
    const parsedValue = parseInt(values)
    if (
      isNaN(parsedValue) ||
      parsedValue === 0 ||
      parsedValue > product.quantity
    ) {
      return Formatter(0)
    } else {
      const price = typeof product.price === 'number' ? product.price : 0
      return Formatter(price * parsedValue)
    }
  }, [values, product.quantity, product.price])

  const { data: businessDetail } = useSWR('/business/profile', userBusiness)

  const isVerified = businessDetail?.is_verified
  const [isDeleted, setIsDeleted] = useState(false)

  const handleDelete = async (): Promise<void> => {
    deleteCartItem(product.id)
    setIsDeleted(true)
  }

  const percentDiscountAmount =
    ((product?.price as number) * product?.carton_discount) / 100
  const discountAmount = (product?.price as number) - percentDiscountAmount
  const discountAmountFormat = Formatter(discountAmount)
  const itemsPerCarton = product?.carton_quantity
  const qtyCart = product?.quantity_in_cart

  const remainingItems = qtyCart % itemsPerCarton
  const cartons = Math.floor(qtyCart / itemsPerCarton)
  const counter = cartons === 0 ? qtyCart : cartons

  const displayMessage = qtyCart < itemsPerCarton
    ? `${qtyCart} unit${qtyCart > 1 ? 's' : ''}`
    : `${counter} carton${counter > 1 ? 's' : ''}${remainingItems > 0 ? ` and ${remainingItems} unit${remainingItems > 1 ? 's' : ''}` : ''}`;

  return (
    <li key={product.id} className="flex border-b-2 py-6">
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <a href={`/product/${product.slug}`}>
          <img
            src={product.image}
            alt={product.name}
            className="h-full w-full object-cover object-center"
          />
        </a>
      </div>

      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between gap-3 text-left text-base font-medium text-gray-900">
            <h3>
              <a href={`/product/${product.slug}`}>{product.name}</a>
            </h3>
            {isVerified === true ? (
              <div>
                {product?.quantity_in_cart >= product?.carton_quantity ? (
                  <>
                    {discountAmount !== product?.price ? (
                      <>
                        <p className="ml-4">&#8358;{discountAmountFormat}</p>
                        <p className="ml-4 text-gray-300">
                          {/* <s>&#8358;{getAmount()}</s> */}
                          <s>&#8358;{unit_price}</s>
                        </p>
                      </>
                    ) : (
                      <p className="ml-4">
                        <span>&#8358;{getAmount()}</span>
                      </p>
                    )}
                  </>
                ) : (
                  <p className="ml-4">
                    {/* <span>&#8358;{unit_price * quantity}</span> */}
                    {
                      parseFloat(getAmount()) === 0 ? <span>Out of Stock</span> : <span>{getAmount()}</span>
                    }
                  </p>
                )}
              </div>
            ) : (
              '###'
            )}
          </div>
          <p className="mt-1 text-left text-sm text-gray-500">{displayMessage}</p>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          <div>
            <div className="flex space-x-2">
              <input
                type="number"
                value={parseFloat(getAmount()) === 0 ? 0 : values}
                min={1}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setValue(e.target.value)
                }
                className="sm:text-md block w-16 rounded-lg border border-gray-300 bg-gray-50 p-1 text-center text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              />
              {parseInt(values) > product.quantity ? (
                <p className="mt-1 text-sm text-[#3b82f7] hover:text-[#3b82f7]">
                  {pathname === '/checkout'
                    ? `Only ${quantity} is available for express shipping`
                    : 'Available on Request'}
                </p>
              ) : // quantity
                values !== quantity.toString() ? (
                <button
                  type="button"
                  disabled={values === ''}
                  onClick={() =>
                    updateCartItem(
                      {
                        product_id: product.id,
                        quantity: parseInt(values),
                      },
                      product.id
                    )
                  }
                  className="font-medium text-[#3b82f7] hover:text-[#3b82f7]">
                  Update
                </button>
                ) : null}
            </div>
          </div>

          <div className="flex">
            <button
              type="button"
              onClick={() => {
                handleDelete().catch(console.error); 
              }}
              className={`${
                isDeleted ? 'pointer-events-none opacity-50' : ''
              } font-medium text-[#3b82f7] hover:text-[#3b82f7]`}>
              Remove
            </button>
          </div>
        </div>
      </div>
    </li>
  )
}

export default CartItem
